<script setup lang="ts">
import { pascalCase } from 'scule';
import { getCmsLayoutConfiguration } from '@shopware-pwa/helpers-next';
import { useDetailPageNavigation, pdpHeaderHeight } from '~/composables/useDetailPageNavigation';
import { computed } from 'vue';
import type { Schemas } from '#shopware';

const props = defineProps<{
    content: Schemas['CmsPage'];
}>();

const { routeName } = useNavigationContext();
if (routeName.value === 'frontend.detail.page') {
    useDetailPageNavigation();
}

const cmsSections = computed<Schemas['CmsSection'][]>(() => {
    return props.content?.sections || [];
});
provide('cmsSections', cmsSections as Ref<Schemas['CmsSection'][]>);

const navBarTopVal = computed(() => pdpHeaderHeight + 'px');

const hasPageElement = (element: string) => {
    if (!element) return false;

    return cmsSections.value.some((section) => {
        return section.blocks.some((block) => {
            return block.slots.some((slot) => {
                // @ts-ignore
                return slot.type === element;
            });
        });
    });
};

const { hasCableExplorer } = useCableExplorer();
hasCableExplorer.value = hasPageElement('cable-explorer');

const DynamicRender = () => {
    const componentsMap = cmsSections.value.map((section) => {
        return {
            name: `CmsSection${pascalCase(section.type)}`,
            component: resolveComponent(`CmsSection${pascalCase(section.type)}`),
            section: section,
        };
    });
    return componentsMap.map((componentObject) => {
        const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(componentObject.section);

        // check if section contains a block with a slot with the type "product-navigation"
        const hasProductNavigation = componentObject.section.blocks.some((block) => {
            return block.slots.some((slot) => {
                // @ts-ignore
                return slot.type === 'product-navigation';
            });
        });

        if (typeof componentObject.component === 'string')
            return h('div', {}, 'There is no ' + componentObject.component);
        return h(
            'div',
            {
                style: {
                    backgroundColor: layoutStyles?.backgroundColor,
                    backgroundImage: layoutStyles?.backgroundImage,
                    backgroundSize: layoutStyles?.backgroundSize,
                },
                class: {
                    'sticky z-5 cms-product-navigation': hasProductNavigation,
                },
            },
            h(componentObject.component, {
                content: componentObject.section,
                class: {
                    ...cssClasses,
                    container: layoutStyles?.sizingMode === 'boxed',
                },
            }),
        );
    });
};
</script>

<template>
    <DynamicRender />
</template>

<style>
.cms-product-navigation {
    top: v-bind(navBarTopVal);
}
</style>
